import React, {useState} from "react";
import {
    AppBar,
    Box,
    Button,
    Container,
    Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Typography
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {NavLink, useNavigate} from "react-router-dom";
import {AccountCircle} from "@mui/icons-material";
import {logout} from "../store/slices/authSlice";
import {useDispatch, useSelector} from "react-redux";
import "../styles/Header.css"
import NotificationsPopover from "../components/NotificationsPopover";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const pages = [
    {
        label: 'Транзакции',
        link: '/transactions'
    },
    {
        label: 'Выплаты',
        link: '/operations'
    },
    {
        label: 'Статистика',
        link: '/statistics'
    },
    {
        label: 'История рефералов',
        link: '/history_refferals',
    },
];

function Header() {
    const [openRegulationDialog, setOpenRegulationDialog] = React.useState(false);

    // const [auth, setAuth] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElNav, setAnchorElNav] = useState(null);
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const user = useSelector((state) => state.auth.user)
    const merchants = useSelector((state) => state.auth.merchants)

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function logoutUser() {
        setAnchorEl(null);
        dispatch(logout())
        navigate("/login", {replace: true});

    }

    function profileUser() {
        setAnchorEl(null);
        navigate("/profile", {replace: true});
    }

    function settingsUser() {
        setAnchorEl(null);
        navigate("/settings", {replace: true});
    }


    function returnUserRate() {
        return user.merchants[0].rate
        // for (let i = 0; i < user.merchants.length; i++) {
        //     let merchant = user.merchants[i]
        //     let totalCommission = parseFloat(merchant.commission) + parseFloat(user.coefficient)
        //     let commissionPercent = Number(totalCommission) / 100;
        //     let rateCommission = merchant.rate + (merchant.rate * commissionPercent)
        //     let result = 0
        //     if (rateCommission > 0) {
        //         result = rateCommission.toFixed(2)
        //     }
        //     arrayValues.push({
        //         id: merchant.id,
        //         type: merchant.type,
        //         value: result
        //         // value: `${merchant.type} - ${result} ₽`
        //     })
        // }
        // console.log('arrayValues', arrayValues)
        // return (
        //     <div className="d-flex align-items-center mx-1">
        //         {/*<Typography > | Баланс: </Typography>*/}
        //         {/*<Typography sx={{*/}
        //         {/*    fontWeight: 700,*/}
        //         {/*    color: '#ffc400'*/}
        //         {/*}} className="mx-1">{user.balance.toFixed(2)} $</Typography>*/}
        //
        //
        //         {arrayValues.map((arr, index) => (
        //             <span key={arr.id} className="d-flex align-items-center">
        //                  <Typography>{arr.type}: </Typography>
        //                 <Typography sx={{
        //                     fontWeight: 700,
        //                     color: '#ffc400'
        //                 }} className="mx-1">{arr.value} ₽</Typography>
        //             </span>
        //         ))}
        //     </div>
        // )
    }

    let pagesMenu = pages.map(page => {
        return page.link == 'documentation' ?
            <MenuItem key={page.label}>
                <a
                    className='document-link'
                    href="https://documenter.getpostman.com/view/22837543/VUjTihem"
                    target='_blank'
                    rel="noopener"
                    aria-label='Github'
                >
                    {page.label}
                </a>
            </MenuItem>
            :
            <MenuItem key={page.label} component={NavLink}
                      to={page.link}
                      onClick={handleCloseNavMenu}>
                <Typography textAlign="center">{page.label}</Typography>
            </MenuItem>
    });


    return (<AppBar position="static">
        <Container maxWidth="xl">
            <Toolbar disableGutters>
                <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom', horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top', horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: {xs: 'block', md: 'none'},
                        }}
                    >
                        {pages.map((page) => (
                            page.link === "documentation" ? (
                                <a
                                    key={page.label}
                                    className='document-link'
                                    href="https://documenter.getpostman.com/view/22837543/VUjTihem"
                                    target='_blank'
                                    rel="noopener"
                                    aria-label='Github'
                                >
                                    {page.label}
                                </a>
                            ) : (
                                <MenuItem key={page.label} component={NavLink}
                                          to={page.link}
                                          onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{page.label}</Typography>
                                </MenuItem>
                            )
                        ))}
                    </Menu>

                    <div className="d-flex align-items-center mx-2">
                        <Typography>Баланс: </Typography>
                        <Typography sx={{
                            fontWeight: 700,
                            color: '#ffc400'
                        }} className="mx-1">{user.balance.toFixed(2)} $</Typography>
                    </div>
                    {user && (<div className="ms-auto d-flex align-items-center">


                        <NotificationsPopover/>

                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle/>
                            <Typography className={'mx-1'}>  {user.login}</Typography>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top', horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top', horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={profileUser}>Профиль</MenuItem>
                            <MenuItem onClick={settingsUser}>Настройки</MenuItem>

                            <MenuItem onClick={logoutUser}>Выйти</MenuItem>
                        </Menu>
                    </div>)}

                </Box>

                <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}, alignItems: 'center'}}>
                    {pages.map((page) =>
                        page.link === "documentation" ? (
                            <a
                                key={page.label}
                                className='document-button'
                                href="https://documenter.getpostman.com/view/22837543/VUjTihem"
                                target='_blank'
                                rel="noopener"
                                aria-label='Github'
                            >
                                {page.label}
                            </a>
                        ) : (
                            <Button
                                component={NavLink}
                                variant="text"
                                to={page.link}
                                size="small"
                                key={page.label}
                                onClick={handleCloseNavMenu}
                                sx={{
                                    my: 2, color: 'white', display: 'block', ':hover': {color: 'white'}, "&.active": {
                                        background: '#dbdbdb33',
                                    }
                                }}>
                                {page.label}
                            </Button>
                        )
                    )
                    }

                    {user && (<div className="ms-auto d-flex align-items-center">


                        <div className='mx-2'>
                            <Button variant="contained" color="warning" size={'small'}
                                    onClick={() => setOpenRegulationDialog(true)}>Регламент</Button>


                            <Dialog
                                fullWidth={true}
                                maxWidth={'lg'}
                                open={openRegulationDialog}
                                onClose={() => setOpenRegulationDialog(false)}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    Регламент работы
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <pre>{
                                            user.system_settings.find(
                                                (item) => item.name === "regulation"
                                            ).value
                                        }</pre>
                                    </DialogContentText>
                                </DialogContent>
                            </Dialog>

                        </div>
                        <div className="d-flex align-items-center mx-1">
                            <Typography>Реферальный код: </Typography>
                            <Typography sx={{
                                fontWeight: 700,
                                color: '#ffc400'
                            }} className="mx-1">{user.own_referral_code}</Typography>

                            <ContentCopyIcon fontSize="small" className="mx-1 " color="white" sx={{
                                cursor: 'pointer',
                            }}
                                             onClick={() => {
                                                 navigator.clipboard.writeText(user.own_referral_code)
                                             }}
                            />
                            |
                        </div>


                        <div className="d-flex align-items-center mx-1">
                            <Typography>Курс: </Typography>
                            <Typography sx={{
                                fontWeight: 700,
                                color: '#ffc400'
                            }} className="mx-1">{returnUserRate()} ₽</Typography>
                        </div>


                        <div className="d-flex align-items-center mx-1">
                            <Typography> | Баланс: </Typography>
                            <Typography sx={{
                                fontWeight: 700,
                                color: '#ffc400'
                            }} className="mx-1">{user.balance.toFixed(2)} $</Typography>
                        </div>


                        <NotificationsPopover/>

                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle/>
                            <Typography className={'mx-1'}>  {user.login}</Typography>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top', horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top', horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={profileUser}>Профиль</MenuItem>
                            <MenuItem onClick={settingsUser}>Настройки</MenuItem>

                            <MenuItem onClick={logoutUser}>Выйти</MenuItem>
                        </Menu>
                    </div>)}
                </Box>
            </Toolbar>
        </Container>
    </AppBar>);
}

export default Header;
