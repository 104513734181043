import {Alert, Typography} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import {unwrapResult} from "@reduxjs/toolkit";
import * as React from "react";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import io from "socket.io-client";
import {authorize} from "../store/slices/authSlice";
import {getNotifications, markNotifications,} from "../store/slices/notificationSlice";
import useMediaQuery from "@mui/material/useMediaQuery";

const socket = io("https://cashout.center");
// const socket = io("https://checkbot.work");
// const socket = io('https://cashout.center', {
//     transports: ["websocket"]
// });
//
// const socket = io("http://localhost:3000");

export default function Socket() {
    const [open, setOpen] = React.useState(false);
    const [notification, setNotification] = useState({
        id: 0,
        title: "",
        description: "",
        severity: "error",
    });
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);

    useEffect(() => {
        console.log(socket);
        socket.on("connect", () => {
            console.log("connected");
        });

        socket.on("disconnect", () => {
            console.log("disconnect");
        });

        socket.on("notification", (data) => {
            console.log("data", data);
            if (data.user_id == user.id) {
                setNotification(data);
                dispatch(getNotifications());
                let userToken = localStorage.getItem("token");
                if (userToken) {
                    dispatch(authorize(userToken));
                }

                setOpen(true);
            }
        });

        return () => {
            socket.off("connect");
            socket.off("disconnect");
            socket.off("ping");
        };
    }, []);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        console.log([notification.id]);

        dispatch(markNotifications([notification.id]))
            .then(unwrapResult)
            .then((result) => {
                dispatch(getNotifications());
            })
            .catch((error) => {
            });
        setOpen(false);
    };

    const vertical = "top";
    const horizontal = "right";
    const isMdUp = useMediaQuery('(min-width: 768px)');

    return (
        <Snackbar
            open={open}
            autoHideDuration={9999999}
            onClose={handleClose}
            anchorOrigin={{vertical, horizontal}}
        >
            <Alert
                onClose={handleClose}
                severity={notification.severity}
                sx={{
                    width: "100%",
                    maxWidth: isMdUp ? "50vw": "100vw",
                    height: "auto",
                }}
            >
                <Typography variant="subtitle2">{notification.title}</Typography>
                <Typography variant="body1">
                    <pre>{notification.description}</pre>
                </Typography>
            </Alert>
        </Snackbar>
    );
}
