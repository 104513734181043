import logo from './logo.svg';
import './App.css';
import React, {useState} from 'react'
import {HashRouter as Router, Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Header from "./layouts/Header";
import Login from "./pages/Login";
import Register from "./pages/Register";
import {useDispatch, useSelector} from "react-redux";
import {authorize, login} from "./store/slices/authSlice";
import {CircularProgress} from "@mui/material";
import {unwrapResult} from "@reduxjs/toolkit";
import Settings from "./pages/Settings";
import axios from "axios";
import Operations from "./pages/Operations";
import Profile from "./pages/Profile";
import SnackBar from "./components/SnackBar";
import SharedLayout from "./layouts/SharedLayout";
import Transactions from "./pages/Transactions";
import ClearRequests from "./pages/ClearRequests";
import Socket from "./components/Socket";
import Statistics from "./pages/Statistics";
import LogsRefferals from "./pages/LogsRefferals";

function App() {


    let isAuthenticate = true;
    const [loading, setLoading] = useState(true);
    const user = useSelector((state) => state.auth.user)
    const dispatch = useDispatch();
    let navigate = useNavigate();

    // let navigate = useNavigate();


    React.useEffect(() => {
        /**
         * Check authorize
         */
        if (user == null) {
            let userToken = localStorage.getItem('token');
            console.log('userToken', userToken)

            if (userToken) {
                dispatch(authorize(userToken))
                    .then(unwrapResult)
                    .then((result) => {
                        console.log('result', result); // => 233
                        setLoading(false);
                        axios.defaults.headers.common['Authorization'] = userToken;


                        // navigate.push('/dashboard')
                        // navigate("/dashboard", {replace: true});
                    })
                    .catch((error) => {
                        navigate("/login", {replace: true});
                        setLoading(false);
                        console.error('error', error); // if there is an error
                    });

            } else {
                setLoading(false)
                // return <Navigate replace to="/login"/>
                navigate("/login", {replace: true});
            }
        } else {
            setLoading(false)
            // return <Navigate replace to="/login"/>
        }
    }, []);


    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <CircularProgress/>
            </div>
        );
    } else {
        return (
            <div className="App">
                <SnackBar/>
                <Socket/>
                <Routes>
                    <Route path='/' element={<SharedLayout/>}>
                        <Route path='/transactions' element={<Transactions/>}/>
                        {/*<Route index element={<Transactions/>}/>*/}
                        <Route path="settings" element={<Settings/>}/>
                        <Route path="operations" element={<Operations/>}/>
                        {/*<Route path="requests" element={<ClearRequests/>}/>*/}
                        <Route path="statistics" element={<Statistics/>}/>
                        <Route path="profile" element={<Profile/>}/>
                        <Route path="history_refferals" element={<LogsRefferals/>}/>
                        <Route path="/" element={<Navigate replace to="/transactions"/>}/>
                    </Route>
                    <Route path="login" element={<Login/>}/>
                </Routes>
            </div>
        );
    }
}

export default App;
