import DeleteIcon from "@mui/icons-material/Delete";
import {
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel, LinearProgress,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import {DataGridPremium, GridActionsCellItem} from "@mui/x-data-grid-premium";
import {unwrapResult} from "@reduxjs/toolkit";
import dayjs from "dayjs";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom"; // Tell webpack that Button.js uses these styles
import CustomToolbar from "../components/CustomToolbar";
import localeGrid from "../components/localeGrid";
import {authorize, authWithDraw} from "../store/slices/authSlice";
import {createOperation, deleteOperations, getOperations,} from "../store/slices/operationSlice";
import {setSnackBar} from "../store/slices/uiSlice";
import "../styles/operation.css";
import CustomDataGrid from "../components/CustomDataGrid";
import useMediaQuery from "@mui/material/useMediaQuery";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { v4 as uuidv4 } from 'uuid';

const currencies = [
    {
        value: "USDT_TRC",
        label: "USDT (TRC-20)",
    },
    {
        value: "BTC",
        label: "BTC",
    },
    {
        value: "XMR",
        label: "XMR",
    },
    {
        value: "LTC",
        label: "LTC",
    },
    {
        value: "BANK",
        label: "Банковская карта (-5% Garantex)",
    },
    {
        value: "SBP",
        label: "СБП (-4% Garantex)",
    },
];

function Operations() {
    function returnTxLInk(txid, currency) {
        let link = "";
        switch (currency) {
            case "BTC":
                return (
                    <div id="permalink_section">
                        <a
                            href={`https://www.blockchain.com/btc/tx/${txid}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {txid}
                        </a>
                    </div>
                );
            case "USDT_TRC":
                return (
                    <div id="permalink_section">
                        <a
                            href={`https://tronscan.org/#/transaction/${txid}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {txid}
                        </a>
                    </div>
                );
            case "XMR":
                return (
                    <div id="permalink_section">
                        <a
                            href={`https://xmrchain.net/tx/${txid}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {txid}
                        </a>
                    </div>
                );
            case "LTC":
                return (
                    <div id="permalink_section">
                        <a
                            href={`https://live.blockcypher.com/ltc/tx/${txid}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {txid}
                        </a>
                    </div>
                );
            case "BANK":
                return <div>{txid}</div>;
        }
    }

    function returnAddressLInk(address, currency) {
        let link = "";
        switch (currency) {
            case "BTC":
                return (
                    <div id="permalink_section">
                        <a
                            href={`https://www.blockchain.com/btc/address/${address}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {address}
                        </a>
                    </div>
                );
            case "USDT_TRC":
                return (
                    <div id="permalink_section">
                        <a
                            href={`https://tronscan.org/#/address/${address}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {address}
                        </a>
                    </div>
                );
            case "XMR":
                return (
                    <div id="permalink_section">
                        <a
                            href={`https://xmrchain.net/address/${address}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {address}
                        </a>
                    </div>
                );
            case "LTC":
                return (
                    <div id="permalink_section">
                        <a
                            href={`https://live.blockcypher.com/ltc/address/${address}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {address}
                        </a>
                    </div>
                );
            case "BANK":
                return <div>{address}</div>;
        }
    }


    function returnArrayActions(params) {
        if (params.row.status == "new") {
            return [
                <GridActionsCellItem
                    icon={<DeleteIcon/>}
                    label="Удалить"
                    onClick={deleteOperation(params.id)}
                />,
            ];
        } else {
            return [];
        }
    }

    const deleteOperation = React.useCallback(
        (id) => () => {
            console.log(id);
            let array = [];
            array.push(Number(id));
            setDeletedOperations(array);

            setOpenDeleteDialog(true);
            // dispatch(deleteTokens(array))
        },
        []
    );

    const isMdUp = useMediaQuery('(min-width: 768px)');

    const [tableHeight, setTableHeight] = React.useState(window.innerHeight - 200);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state) => state.auth.user);
    const operations = useSelector((state) => state.operation.operations);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const [deletedOperations, setDeletedOperations] = React.useState([]);
    const [openShowDialog, setOpenShowDialog] = React.useState(false);
    const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
    const [checkedAddressFromUser, setCheckedAddressFromUser] =
        React.useState(false);

    const [access, setAccess] = React.useState(false);
    const [openAccessDialog, setOpenAccessDialog] = React.useState(true);

    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState([]);
    const handleDetailPanelExpandedRowIdsChange = useCallback((newIds) => {
        setDetailPanelExpandedRowIds(newIds);
    }, []);

    function returnStatus(status) {

        if (!isMdUp) {
            switch (status) {
                case "new":
                    return <Chip icon={<CheckBoxOutlineBlankIcon/>}
                                 sx={{
                                     '& .MuiChip-label': {
                                         paddingLeft: "0px"
                                     },
                                 }}
                    />;
                case "wait_delete":
                    return (
                        <Chip
                            sx={{
                                background: "#ffeb3b",
                                '& .MuiChip-label': {
                                    paddingLeft: "0px"
                                },
                            }}
                            icon={<AccessTimeIcon/>}
                        />
                    );
                case "decline":
                    return (
                        <Chip
                            icon={<CloseIcon color={"white"}/>}
                            sx={{
                                '& .MuiChip-label': {
                                    paddingLeft: "0px"
                                },
                                background: "#f44336",
                                color: "#fff",
                            }}
                        />
                    );
                case "success":
                    return <Chip icon={<DoneIcon/>} color="success"
                                 sx={{
                                     '& .MuiChip-label': {
                                         paddingLeft: "0px"
                                     },
                                 }}
                    />;
                default:
                    return <Chip label="Неизвестно"
                                 sx={{
                                     '& .MuiChip-label': {
                                         paddingLeft: "0px"
                                     },
                                 }}
                    />;
            }

        } else {
            switch (status) {
                case "new":
                    return <Chip label="Новая"/>;
                case "wait_delete":
                    return (
                        <Chip
                            label="Ожидает отмены"
                            sx={{
                                background: "#ffeb3b",
                            }}
                        />
                    );
                case "decline":
                    return (
                        <Chip
                            label="Отмена"
                            sx={{
                                background: "#f44336",
                                color: "#fff",
                            }}
                        />);
                case "success":
                    return <Chip label="Успешно" color="success"/>;
                default:
                    return <Chip label="Неизвестно"/>;
            }

        }
    }


    const [columns, setColumns] = useState([
        {
            field: "id",
            headerName: "ID",
            width: 75,
        },

        {
            field: "type",
            headerName: "Тип",
            width: 150,
            renderCell: (params) =>
                params.value == "withdraw" ? (
                    <Chip label="Ручной вывод" size="small" color="primary"/>
                ) : (
                    <Chip label="Автовывод" size="small" color="secondary"/>
                ),
        },

        {
            field: "amount",
            headerName: "Сумма",
            width: 100,
        },

        {
            field: "txid",
            headerName: "Txid",
            width: 100,
            renderCell: (params) =>
                returnTxLInk(params.row.txid, params.row.currency),
        },
        {
            field: "status",
            headerName: "Статус",
            width: isMdUp ? 120 : 40,
            renderCell: (params) => returnStatus(params.value),

        },

        {
            field: "comment",
            headerName: "Комментарий/ Банк",
            width: 120,
        },
        {
            field: "admin_comment",
            headerName: "Комментарий админа",
            width: 180,
        },
        {
            field: "address",
            headerName: "Адрес/Карта/Телефон",
            width: 250,
            renderCell: (params) =>
                returnAddressLInk(params.row.address, params.row.currency),
        },
        {
            field: "currency",
            headerName: "Валюта",
            width: 120,
        },
        {
            field: "created_at",
            headerName: "Дата создания",
            width: 150,
            valueFormatter: (params) => {
                return dayjs(params.value).format("DD.MM.YYYY HH:mm");
            },
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Действия",
            filterable: true,
            width: 50,
            getActions: (params) => returnArrayActions(params),
        },

    ]);

    useEffect(() => {
        if (!isMdUp) {
            setColumns((cols) =>
                cols.filter(
                    (col) => col.field !== "comment" && col.field !== "admin_comment" && col.field !== "address" && col.field !== "currency" && col.field !== "created_at"
                )
            );
        }
    }, [isMdUp]);


    React.useEffect(() => {
        if (checkedAddressFromUser) {
            setValue("address", user.address);
        } else {
            setValue("address", "");
        }
    }, [checkedAddressFromUser]);

    const resize = () => {
        if (isMdUp) {
            setTableHeight(window.innerHeight - 200);
        } else {
            setTableHeight(window.innerHeight - 200);
        }
    };


    React.useEffect(() => {
        resize();
        window.addEventListener("resize", resize);
        return () => {
            window.removeEventListener("resize", resize);
        };
    }, []);

    const {register, control, handleSubmit, setValue, reset, getValues, watch} =
        useForm({
            defaultValues: {
                id: null,
                type: "withdraw",
                amount: 0,
                status: "new",
                comment: "",
                address: "",
                currency: currencies[0].value,
            },
        });

    let currency = watch("currency");

    const addressLabel = useMemo(() => {
        if (currency === "BANK") {
            return "Карта";
        }
        if (currency === "SBP") {
            return "Телефон";
        }
        return "Адрес";
    }, [currency]);

    const commentLabel = useMemo(() => {
        if (currency === "BANK") {
            return "Комментарий";
        }
        if (currency === "SBP") {
            return "Банк";
        }
        return "Комментарий";
    }, [currency]);

    const {
        control: controlAccess,
        handleSubmit: handleSubmitAccess,
        reset: resetAccess,
    } = useForm({
        defaultValues: {
            password: "",
        },
    });

    const closeDeleteDialog = (event, reason) => {
        setSelectionModel([]);
        setOpenDeleteDialog(false);
    };

    function dialogDeleteOperations() {

        dispatch(deleteOperations(deletedOperations))
            .then(unwrapResult)
            .then((result) => {
                dispatch(
                    setSnackBar({
                        open: true,
                        text: "Успешно",
                    })
                );
                let userToken = localStorage.getItem("token");
                if (userToken) {
                    dispatch(authorize(userToken));
                }
                setOpenDeleteDialog(false);
            })

    }

    const closeEditDialog = (event, reason) => {
        setOpenEditDialog(false);
    };

    const onAccess = (data) => {
        console.log(data);
        dispatch(authWithDraw(data))
            .then(unwrapResult)
            .then((result) => {
                dispatch(
                    setSnackBar({
                        open: true,
                        text: "Успешно",
                    })
                );
                setAccess(true);
                setOpenAccessDialog(false);
                dispatch(getOperations());
            })
            .catch((error) => {
                dispatch(
                    setSnackBar({
                        open: true,
                        text: "Ошибка",
                    })
                );
                navigate("/");

                console.error("error", error); // if there is an error
            });
    };

    const [loadingCreateOperation, setLoadingCreateOperation] = React.useState(false);

    const onCreateOperation = (data) => {
        if (loadingCreateOperation) return; // Предотвращаем повторную отправку

        setLoadingCreateOperation(true);
        // Генерируем уникальный идентификатор запроса
        const request_id = uuidv4();

        // Добавляем его к данным запроса
        const requestData = { ...data, request_id };

        dispatch(createOperation(requestData))
            .then(unwrapResult)
            .then((result) => {
                dispatch(
                    setSnackBar({
                        open: true,
                        text: "Успешно",
                    })
                );
                let userToken = localStorage.getItem("token");
                if (userToken) {
                    dispatch(authorize(userToken));
                }
                setLoadingCreateOperation(false);
                setOpenCreateDialog(false);
            })
            .catch((error) => {
                dispatch(
                    setSnackBar({
                        open: true,
                        text: "Ошибка",
                    })
                );
                setLoadingCreateOperation(false);
                setOpenCreateDialog(false);
                console.error("error", error); // if there is an error
            });

    };

    function startCreateOperation() {
        reset();
        setOpenCreateDialog(true);
    }

    if (!access) {
        return (
            <div
                className="d-flex align-items-center justify-content-center"
                style={{
                    height: `calc(100vh - 90px)`,
                }}
            >
                <CircularProgress/>

                <Dialog
                    open={openAccessDialog}
                    maxWidth="sm"
                    fullWidth={true}
                    onClose={() => {
                        setOpenAccessDialog(false);
                        navigate("/");
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Пароль для входа</DialogTitle>
                    <DialogContent>
                        <div className="row">
                            <div className="col-12 mt-3">
                                <form>
                                    <div>
                                        <Controller
                                            name="password"
                                            control={controlAccess}
                                            rules={{
                                                required: "Поле обязательно для заполнения",
                                            }}
                                            render={({field, fieldState}) => (
                                                <TextField
                                                    className="w-100"
                                                    type="password"
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error?.message}
                                                    label="Пароль"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setOpenAccessDialog(false);
                                navigate("/");
                            }}
                        >
                            Отмена
                        </Button>
                        <Button onClick={handleSubmitAccess(onAccess)}>Войти</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    return (
        <div>
            <Card sx={{minWidth: 275}}>
                <CardContent className="text-start p-3">
                    {/*<Typography color="text.primary">Операции</Typography>*/}

                    <div className="row">
                        <div className="col-12 justify-content-end text-end">
                            <Button
                                variant="contained"
                                className=""
                                onClick={startCreateOperation}
                            >
                                Заявка на вывод средств
                            </Button>

                            {/*<Button variant="contained" className="ms-3" onClick={deleteSelected}*/}
                            {/*        disabled={selectionModel.length == 0}>*/}
                            {/*    Удалить выбранные*/}
                            {/*</Button>*/}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 mt-3">
                            <div style={{height: tableHeight, width: "100%"}}>

                                <CustomDataGrid
                                    tableId="operationsTable" // Уникальный идентификатор для таблицы статистики
                                    columns={columns}
                                    initialState={{
                                        sorting: {
                                            sortModel: [
                                                {
                                                    field: "id",
                                                    sort: "desc"
                                                }
                                            ]
                                        },
                                        pinnedColumns: {right: ['actions']}}}

                                    rows={operations}
                                    rowHeight={40}
                                    experimentalFeatures={{aggregation: true}}
                                    componentsProps={{
                                        toolbar: {
                                            filename: 'Операции',
                                            showQuickFilter: true,
                                        }
                                    }}
                                    onCellDoubleClick={(params, event) => {
                                        switch (params.field) {
                                            case "txid":
                                                if (params.value) {
                                                    window.open(
                                                        `https://tronscan.org/#/transaction/${params.value}`,
                                                        "_blank"
                                                    );
                                                }
                                                break;
                                            case "address":
                                                if (params.value) {
                                                    window.open(
                                                        `https://tronscan.org/#/address/${params.value}`,
                                                        "_blank"
                                                    );
                                                }
                                                break;
                                        }
                                    }}
                                    getRowClassName={(params) => `color-${params.row.status}`}
                                    disableSelectionOnClick
                                    onSelectionModelChange={setSelectionModel}
                                    selectionModel={selectionModel}

                                    detailPanelExpandedRowIds={isMdUp ? [] : detailPanelExpandedRowIds}
                                    onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                                    getDetailPanelContent={({row}) =>
                                        !isMdUp && <Box sx={{p: 2}}>
                                            <Typography variant={"h6"}
                                                        color="text.primary">{`Заявка #${row.id}`}</Typography>

                                            {row.comment && (
                                                <Typography
                                                    color="text.primary">{`Комментарий: ${row.comment}`}</Typography>
                                            )}
                                            {row.admin_comment && (
                                                <Typography
                                                    color="text.primary">{`Комментарий админа: ${row.admin_comment}`}</Typography>
                                            )}
                                            <Typography
                                                color="text.primary">{`Адрес/Карта/Телефон: ${row.address}`}</Typography>
                                            <Typography color="text.primary">{`Валюта: ${row.currency}`}</Typography>
                                            <Typography
                                                color="text.primary">{`Дата создания: ${dayjs(row.created_at).format("DD.MM.YYYY HH:mm")}`}</Typography>
                                        </Box>
                                    }
                                    getDetailPanelHeight={() => 175}
                                />


                            </div>
                        </div>
                    </div>

                    <Dialog
                        open={openDeleteDialog}
                        onClose={closeDeleteDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Подтвердить действие
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Удалить операцию?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDeleteDialog}>Отмена</Button>
                            <Button onClick={() => dialogDeleteOperations()} autoFocus>
                                Да
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={openCreateDialog}
                        maxWidth="sm"
                        fullWidth={true}
                        onClose={() => setOpenCreateDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">Заявка на вывод</DialogTitle>
                        <DialogContent>
                            <div className="row">
                                <div className="col-12 mt-3">
                                    <form>
                                        <div>
                                            <Controller
                                                name="amount"
                                                control={control}
                                                rules={{
                                                    required: "Поле обязательно для заполнения",
                                                    max: {
                                                        value: user.balance,
                                                        message: `Максимальная сумма $${user.balance}`,
                                                    },
                                                    // min: {
                                                    //     value: 100,
                                                    //     message: `Максимальная сумма $100`
                                                    // },
                                                }}
                                                render={({field, fieldState}) => (
                                                    <TextField
                                                        className="w-100"
                                                        type="number"
                                                        error={!!fieldState.error}
                                                        helperText={fieldState.error?.message}
                                                        label="Сумма"
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </div>


                                        <div className="mt-3">
                                            <FormControl fullWidth>
                                                <InputLabel id="currency-label">
                                                    Выберите валюту
                                                </InputLabel>
                                                <Controller
                                                    name="currency"
                                                    control={control}
                                                    render={({field}) => (
                                                        <Select
                                                            {...field}
                                                            // disabled={true}
                                                            value={field.value}
                                                            labelId="currency-label"
                                                        >
                                                            {currencies.map((option) => (
                                                                <MenuItem
                                                                    key={option.value}
                                                                    value={option.value}
                                                                >
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                        </div>

                                        <div className="mt-3">
                                            <Controller
                                                name="comment"
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        className="w-100"
                                                        label={commentLabel}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </div>

                                        <div className="mt-3">
                                            <Controller
                                                name="address"
                                                control={control}
                                                rules={{
                                                    required: "Поле обязательно для заполнения",
                                                }}
                                                render={({field, fieldState}) => (
                                                    <TextField
                                                        className="w-100"
                                                        label={addressLabel}
                                                        error={!!fieldState.error}
                                                        helperText={fieldState.error?.message}
                                                        disabled={checkedAddressFromUser}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            {
                                loadingCreateOperation ? <div style={{width: '100%'}}>
                                    <LinearProgress />
                                </div> : <>
                                    <Button onClick={() => setOpenCreateDialog(false)}>Отмена</Button>
                                    <Button onClick={handleSubmit(onCreateOperation)} disabled={loadingCreateOperation}>
                                        Сохранить
                                    </Button>
                                </>

                            }

                        </DialogActions>
                    </Dialog>

                </CardContent>
            </Card>
        </div>
    );
}

export default Operations;
